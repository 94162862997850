.hotel {
  border-radius: 10px;
  background: #f6f6f6;
  width: 20%;
  position: relative;
  cursor: pointer;
}

.hotel img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16/9;
}

.hotel-info {
  padding: 15px 10px;
  background: #f6f6f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hotel-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;

  color: #333333;
}

.hotel-time {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;

  color: #333333;
}

@media screen and (max-width: 960px) {
  .hotel {
    width: 100%;
    margin-bottom: 30px;
  }
}
