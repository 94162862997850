.button {
  border-radius: 10px;
  padding: 2px 15px;
  height: fit-content;
  min-height: 37px;
  cursor: pointer;
  font-size: 18px;
}

.button-primary {
  border: 1px solid #11a5ea;
  background-color: #11a5ea;
  color: white;
}

.button-secondary {
  border: 1px solid #11a5ea;
  background: transparent;
  color: #11a5ea;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
