.hotel-infos-places {
  width: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  background-color: #f6f6f6;
}

.hotels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 0;
}

.hotel-infos-places .hotel-info {
  background: #f6f6f6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-width: 180px;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.hotel-infos-places img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.hotel-info p {
  color: #777777;
  font-size: 11px;
  line-height: 11px !important;
}

.hotel-info h2 {
  color: #535353;
  font-size: 15px;
}

.hotel-info-map {
  background-color: white;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-c button {
  background: #11a5ea !important;
  margin: 10px !important;
  border-radius: 50% !important;
  color: white !important;
  opacity: 1 !important;
}

.gm-ui-hover-effect > span {
  background-color: white !important;
}

.hotel-distance {
  position: absolute;
  left: 0;
  background: #11a5ea;
  margin: 10px;
  color: white;
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 12px;
}
