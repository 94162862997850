.contact-us {
  display: flex;
  justify-content: space-between;
  padding: 50px 150px;
}

.contact-us p {
  font-size: 20px;
  line-height: 30px;
  color: rgb(85, 85, 85);
}

.contact-infos {
  margin-bottom: 25px;
}

.contact-infos div {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 3px;
}

.contact-infos a {
  text-decoration: none;
  color:blue;
}

.contact-infos span {
  color: rgb(17, 165, 234);
  font-size: 18px;
  letter-spacing: 2px;
}

@media screen and (max-width: 960px) {
  .contact-us {
    padding: 20px 30px;
    flex-direction: column;
  }

  .contact-us > div {
    width: 100% !important;
  }
}
