.book-room {
  display: flex;
  justify-content: space-between;
}

.book-room .book-room-right {
  text-align: right;
  width: 250px;
}

.book-room .book-room-left p,
.book-room .book-room-right p {
  color: #878787;
  line-height: 10px;
  line-height: 13px;
}

.book-room .book-room-left p span {
  color: black;
}

.book-room-left {
  width: 50%;
}

.book-room-left span {
  float: right;
}

.book-room .book-room-right a {
  color: #2194ff;
}

.book-room-data {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 15px;
}

.book-room-data textarea {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid gray;
  padding: 15px 40px 15px 20px;
  height: 40px;
  margin: 10px;
}

.book-room-data input {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid gray;
  padding: 15px 40px 15px 20px;

  margin: 10px;
}

.book-room-data select {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid gray;
  padding: 15px 40px 15px 20px;

  margin: 10px;
}

.book-complete {
  display: flex;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
}

.book-complete .circle {
  width: 80px;
  height: 80px;
  background-color: #29976d;
  position: relative;
  border-radius: 50%;
}

.book-complete .circle:after {
  content: '';
  background-color: transparent;
  position: absolute;
  left: 32px;
  top: 13px;
  width: 14px;
  border-bottom: 5px solid white;
  height: 35px;
  border-right: 5px solid white;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#cc > div {
  width: 100%;
}

.promo {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media screen and (max-width: 768px) {
  p span {
    display: flex;
    flex-direction: column;
    line-height: 20px;
  }

  .book-room-left span {
    float: none;
  }

  .book-room-left {
    width: auto;
  }

  .book-room-right {
    width: auto;
  }

  .promo {
    flex-direction: column;
  }

  .promo input {
    margin-bottom: 20px;
  }
}
