.nav {
  padding: 5px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  z-index: 10;
}

.nav.transparent {
  background: #FFFFFF;
}

.nav.white {
  background: white;
  -webkit-box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
  -moz-box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
  box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
}

.nav-menu {
  display: flex;
}

.subs {
  display: flex;
  width: 56%;
  justify-content: space-between;
  align-items: center;
}

.nav-menu div {
  margin-right: 25px;
  color: #11a5ea;
  font-size: 18px;
}

.menu-toggle {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav,
  .nav.transparent {
    background-color: white;
    padding: 5px 0;
  }

  .nav img {
    padding: 0 25px;
  }

  .menu-toggle {
    display: flex;
  }

  .nav-menu {
    flex-direction: column;
  }

  .nav-menu a {
    margin: 20px 0;
  }

  .subs {
    position: absolute;
    flex-direction: column;
    margin-top: 314px;
    background: #FFFFFF;
    width: 100%;
    align-items: flex-start;
    -webkit-box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
    -moz-box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
    box-shadow: 0px 14px 14px -15px rgba(189, 189, 189, 1);
    padding: 0 25px;
  }

  .menu-toggle {
    margin-right: 25px;
  }

  .user-login {
    width: 100%;
    border-top: 2px solid #11a5ea;
    padding: 20px 0;
  }
}
