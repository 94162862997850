.landing-header {
  background-image: url('../../../../assets/images/homepage/bg/header-light-blue-gradient.jpg');
  height: 90vh;
  min-height: 650px;
  background-repeat: no-repeat;
  background-position: center center;
}

.landing-header .home-header-content {
  position: relative;
  padding: 110px 0;
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 999px;
  margin: 0 auto;
  justify-content: space-between;
}

.landing-header .home-header-content h1 {
  text-align: center;
  color: #11a5ea;
  font-size: 55px;
  font-weight: 200;
  line-height: 1.1em;
  letter-spacing: 0;
  font-style: normal;
}

.landing-header .home-header-content h3 {
  font-weight: 300;
  line-height: 1.4em;
  letter-spacing: 0px;
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;

}

@media screen and (max-width: 960px) {
  .landing-header .home-header-content {
    width: 100%;
    height: 35vh;
  }

  #TitleSubtext {
    display: none;
  }

  .landing-header {
    background-size: 250%;
    background-image: url('../../../../assets/images/homepage/bg/header-mobile-light-blue-gradient.jpg');
  }

  .landing-header .home-header-content h1 {
    font-size: 50px;
    line-height: 1;
  }

  .landing-header .home-header-content h3 {
    line-height: 0.8em;
  }

  .home-header-content h1 {
    filter: drop-shadow(3px 3px #ffffff);
  }

  #launcher {
    visibility: hidden;
  }
}
