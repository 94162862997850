.footer {
  background-color: #333333;
  padding: 20px 120px 40px 120px;
}

.footer .sections {
  display: flex;
  justify-content: space-between;
}

.social-section {
  display: flex;
  margin-top: 25px;
}

.footer .section.section-menu,
.footer .section.section-sub {
  margin-top: 40px;
}

.footer .section.section-contact {
  margin-top: 110px;
}

.footer .section .social-section .social {
  background: #2194ff;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.footer .section .menu {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
}

.footer .section .menu span {
  color: #2194ff;
}

.footer .section .menu a {
  color: white;
  text-decoration: none;
}

.footer .section input {
  background: #ffffff;
  border-radius: 12px;
  border: none;
  padding: 15px 40px 15px 20px;
}

.footer .section .paper {
  position: absolute;
  margin-left: -35px;
  margin-top: 10px;
}

.footer .hr {
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0.2;
  margin: 60px 0 45px 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-payment img {
  margin-right: 15px;
}

.footer-bottom .section {
  display: flex;
}

.footer-bottom .section .menu {
  margin-right: 15px;
}

@media screen and (max-width: 960px) {
  .footer {
    padding: 60px;
  }
  .footer .sections {
    flex-direction: column;
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer .section.section-contact {
    margin-top: 20px;
  }

  #bottomLinks {
    display: flex;
    justify-content: center;
    gap: 5px; /* Further reduced the gap between the links */
    flex-wrap: nowrap; /* Keep all items in a single line */
    padding: 0 10px; /* Reduce horizontal padding */
    width: 100%; /* Ensure it uses the full available width */
    max-width: 90%; /* Limit the width to 90% to keep it compact */
    box-sizing: border-box; /* Prevents padding from affecting width calculation */
    margin-top: 25px; /* Add margin-top to create space above the links */
    margin-bottom:10px;
  }

  #Copyright {
    margin-top: 20px;
  }

  .footer > .container {
    text-align:center;
    padding:0px;
  }

  .section .social-section {
    align-items: center;
    justify-content: center;
  }

  .footer-bottom .menu {
    white-space: nowrap; /* Prevent wrapping of the text */
    min-width: 50px; /* Decrease minimum width to make links more compact */
    padding: 0 5px; /* Further reduce padding inside links */
  }

  .section-sub .menu,
  .section-menu .menu {
    font-size: 16px !important; /* Force the font size to 16px */
  }

  .footer-bottom-payment {
    display: flex;
    justify-content: center; /* Centers the images horizontally */
    align-items: center; /* Ensures images are vertically centered */
    gap: 15px; /* Spacing between the images */
    width: 100%; /* Ensures full container width */
    box-sizing: border-box; /* Prevents padding from affecting width calculation */
  }

  .footer-bottom-payment img {
    margin-right: 15px; /* Keeps spacing between the images */
  }

  /* Optional: If you want to ensure that the last image doesn't have extra margin */
  .footer-bottom-payment img:last-child {
    margin-right: 0;
  }

}
