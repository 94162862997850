.booking-n {
  padding: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 5px 0px rgba(209, 209, 209, 1);
  margin-bottom: 35px;
  cursor: pointer;
  display: flex;
  align-items:center;
}

.booking-n-image {
  width: 100px;
  height: 100px;
}

.booking-n-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.booking-n-info-part {
  padding: 0 15px;
}

.booking-n-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.booking-n-info-part p {
  font-size: 15px;
  color: gray;
}

.no-bookings {
  padding: 100px 150px;
  text-align: center;
  height: 39%;
}

@media screen and (max-width: 960px) {
  .booking-n-info-part {
    padding: 0 15px;
  }
}