.hotel-inside {
  padding: 0px 40px 40px;
}

.hotel-inside-header h1 {
  margin-bottom: 0;
}

.hotel-inside-header p {
  margin-top: 0px;
  letter-spacing: 2px;
}

.hotel-amenities {
  display: flex;
  flex-direction: column;
  background: #ebf7ff;
  width: 44%;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.hotel-amenities-list {
  column-count: 3;
  overflow-x: auto;
  height: 340px;
  background: #ebf7ff;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.hotel-amenities-list-tag {
  color: #333;
  font-size: 15px;
  display: list-item;
  margin-left: 35px;
}

.hotel-amenities-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.hotel-amenities-list::-webkit-scrollbar {
  height: 5px;
  background-color: #ffffff;
}

.hotel-amenities-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bdbdbd;
}

.hotel-section {
  display: flex;
  flex-direction: row;
}

.hotel-images {
  display: flex;
  height: 400px;
  width: 59%;
}

.hotel-images-single {
  margin-right: 20px;
  width: 40%;
}

.hotel-images-single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.hotel-images-multi {
  display: grid;
  width: calc(3 * 150px + 2 * 10px);
  aspect-ratio: 1;
  grid-template-columns: repeat(2, auto);
}

.hotel-images-multi div {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.hotel-images-multi div img {
  width: 200px;
  margin-bottom: 10px;
  height: 195px;
  object-fit: cover;
  border-radius: 10px;
}

.hotel-info-book {
  margin-top: 30px;
  background-color: #ebf7ff;
  border-radius: 10px;
  min-height: 298px;
}

.hotel-room-options:hover {
  background-color: rgb(201 201 201 / 50%);
  cursor: pointer;
  border-radius: 5px;
}

.hotel-info-book .section {
  font-size: 15px;
}

.hotel-room-options {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.hotel-room-options .check {
  color: rgba(51, 51, 51, 0.501557);
}

.hotel-room-list {
  padding: 15px;
  max-height: 460px;
  overflow-y: auto;
}

.option-room select {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px;
  margin: 10px;
}

.hotel-d-m {
  display: flex;
  justify-content: space-between;
}

.hotel-d {
  width: 50%;
}

.hotel-d p {
  line-height: 32px;
}

.option-room .react-datepicker-wrapper {
  width: 100%;
}

:where(.css-dev-only-do-not-override-qfvobc).ant-picker .ant-picker-clear {
  margin-right: 10px;
}

.option-room .ant-picker-input {
  display: flex;
  flex-direction: row-reverse;
  height: 32px;
  width: 100%;
}

.option-room .ant-picker-input svg {
  fill: #11a5ea;
}

.option-room .ant-picker-input input {
  border-radius: 10px;
  border: 1px solid #dadada;
  width: 90%;
  background: white;
  padding: 5px;
}

.ant-picker-clear {
  right: 10px;
}

.option-toggles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .hotel-section {
    flex-direction: column;
  }

  .hotel-images-single {
    display: none;
  }

  .hotel-amenities {
    width: 100%;
    margin-top: 20px;
  }

  .hotel-d-m {
    flex-direction: column;
  }

  .hotel-d {
    width: 100%;
  }

  .hotel-map {
    width: 100% !important;
  }

  .hotel-images-multi div img {
    width: 160px;
  }

  .hotel-amenities-list {
    column-count: 2;
  }
}
