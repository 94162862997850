.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.swiper-slide .city-name {
  position: absolute;
  background: #11a5ea9c;
  color: white;
  font-size: 25px;
  padding: 5px;
}

.popular-list {
  display: flex;
  justify-content: space-between;
}

.hr {
  width: 122px;
  height: 11px;
  margin-bottom: 25px;
  background: #2194ff;
  border-radius: 15px;
}
