.qr-page {
  background-image: linear-gradient(
    0deg,
    hsl(199deg 86% 49%) 0%,
    hsl(203deg 81% 61%) 22%,
    hsl(205deg 80% 68%) 40%,
    hsl(206deg 80% 74%) 55%,
    hsl(207deg 80% 79%) 67%,
    hsl(208deg 80% 83%) 75%,
    hsl(209deg 80% 88%) 81%,
    hsl(210deg 80% 92%) 86%,
    hsl(210deg 80% 96%) 92%,
    hsl(0deg 0% 100%) 100%
  );
  height: 100vh;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
}

.qr-share {
  display: flex;
  justify-content: flex-end;
}

.qr-share svg {
  font-size: 30px;
}

.qr-logo {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.qr-logo-content {
  -webkit-box-shadow: 0px 0px 36px 1px rgba(140, 140, 140, 0.79);
  -moz-box-shadow: 0px 0px 36px 1px rgba(140, 140, 140, 0.79);
  box-shadow: 0px 0px 36px 1px rgba(140, 140, 140, 0.79);

  border-radius: 10px;
}

.qr-app-text {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-direction: column;
}

.qr-app-text h2 {
  color: rgb(48, 48, 48);
  font-size: 25px;
}

.qr-app-text p {
  text-align: center;
  font-size: 17px;
  color: rgb(87, 87, 87);
  margin: 0;
  line-height: 25px;
}

.qr-apps {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.qr-apple {
  width: 239px;
  height: 85px;
  margin-left: 1px;
}

.qr-link {
  position: absolute;
  bottom: 20px;
  display: flex;
  width: 89%;
  justify-content: center;
}

.qr-link a {
  color: white;
  font-weight: 600;
  text-decoration: none;
  font-size: 17px;
}
