.team-container {
  padding: 50px 150px;
}

.team-container b {
  font-size: 30px;
  color: rgb(104, 104, 104);
}

.team-container span {
  font-size: 25px;
  color: rgb(104, 104, 104);
}

.team-container p {
  font-size: 18px;
  line-height: 30px;
  color: rgb(90, 90, 90);
}

.team-container h1 {
  font-size:40px;
}

.row {
  margin-top:30px;
}

@media screen and (max-width: 960px) {
  .team-container {
    padding: 20px 30px;
  }
}
