.landing-hotels {
  width: 122px;
  color: white;
  background: #2194ff;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  padding: 5px 0;
  justify-content: center;
  margin-top: 30px;
}

.landing-hotels-view {
  display: flex;
  justify-content: space-between;
}

.landing-container {
  padding-bottom: 60px;
}

.landing-container h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 64px;
  color: #333333;
}

.landing-container p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgba(51, 51, 51, 0.501557);
}

.landing-hotels-list {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.hotels-info-content.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.hotels-info-content {
  width: 100%;
}

.hotels-info-list {
  display: flex;
  justify-content: space-between;
}

.hotels-info-list h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin-top:55px;
  color: #333333;
}

.hotels-info-list h1 {
  margin-top: 65px;
}

.hotels-info-list .hr {
  width: 122px;
  height: 11px;
  margin-bottom: 25px;
  background: #2194ff;
  border-radius: 15px;
}

.hotels-info-list p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  color: #333333;
}

.landing-apps {
  background-color: #999595;
  border-radius: 25px;
  padding: 80px 120px 0;
  margin-bottom:100px;
}

.landing-apps-container {
  display: flex;
  justify-content: space-between;
}

.landing-apps .landing-apps-intro h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin-top: 0;
  color: #ffffff;
}

.landing-apps-intro {
  width:60%;
}

.landing-apps-buttons {
  padding-bottom: 90px;
}

.landing-apps-button {
  /*background: #2194ff;*/
  border-radius: 12px;
  color: white;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  padding-bottom:12px;
  padding-right:15px;
}

.landing-apps-button span {
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.landing-apps-buttons {
  display: flex;
}

.landing-app {
  display: flex;
  justify-content: flex-end;
  align-items:last baseline;
}

.UseCasesLeft, .UseCasesMid {
  padding-top:60px;
  padding-right:60px;
  float:left;
}

.UseCasesRight {
  padding-top:60px;
}

.AllUseCases {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.quotes-icon {
  margin-right:50px;
}

@media screen and (max-width: 960px) {
  .landing-container {
    padding-top: 10px;
    padding-bottom: 60px;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
  }
  
  .landing-hotels-list {
    flex-direction: column;
  }

  .hotels-info-list {
    flex-direction: column;
  }

  .hotels-info-list > div {
    width: 100% !important;
  }

  .landing-apps {
    padding: 40px 0% 0px; /* Adjust padding */
    text-align: center; /* Align text content */
    margin: 0 auto; /* Center the container */
    margin-bottom: 80px; /* Increased bottom margin */
    max-width: 100%; /* Ensure it doesn't exceed the viewport */
    box-sizing: border-box; /* Include padding in width calculations */
    overflow: hidden; /* Prevents content overflow */
  }

  .landing-apps-container {
    width: 100%;
    max-width: 1200px; /* Restrict max width */
    margin: 0 auto; /* Center the container */
    padding: 0 20px; /* Add inner spacing */
    box-sizing: border-box;
  }

  .landing-apps-intro {
    width: 100%;
    max-width: 600px; /* Limit max width for better alignment */
    margin: 0 auto; /* Center content */
    height: auto; /* Allow height to adjust based on content */
  }

  .landing-apps-button {
    display: flex;
    justify-content: center; /* Center the button content */
    align-items: center;
    margin: 0; /* Remove unintended margins */
    padding: 0; /* Reset padding */
  }
  
  .landing-apps-button img {
    width: auto;
    height: auto;
    max-width: 180px; /* Increase the maximum width */
    max-height: 80px; /* Increase the maximum height */
    object-fit: contain; /* Maintain aspect ratio */
  }

  .landing-apps-buttons {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap if needed */
    justify-content: center; /* Center align buttons */
    gap: 15px; /* Add spacing between buttons */
    width: 100%; /* Full width */
    max-width: 350px; /* Optional: Prevent stretching */
    margin: 40px auto 0px; /* Increased margin-top to 40px */
  }

  .landing-app {
    display: none;
  }

  .landing-hotels-view {
    display: block;
  }

  .landing-header {
    height: 100vh;
    min-height: 650px;
  }
  .hotels-info-list h3 {
    margin-top:0px;
  }

  .hotels-info-content {
    text-align:center;
  }

  .UseCases {
    text-align:center;
    padding-right:0px;

  }

  .UseCasesLeft, .UseCasesMid {
    padding-right:0px;
    float:none;
  }

  .UseCasesMid {
    padding-top:0px;
  }

  .UseCasesRight {
    padding-top:0px;
  }

  .PopularCities {
    text-align:center;
  }

  .hr {
    display:inline-block;
  }

  .quotes-icon {
    margin-right:0px;
  }

  .quotes-bottom-p {
    font-size:17px !important;
  }
}