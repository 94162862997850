.bookings-page {
  margin-top: 50px;
  margin-bottom:50px;
  padding: 0 150px 0 150px;
}

#NapsBooked {
  padding-top:10px;
  padding-bottom:10px;
}

@media screen and (max-width: 960px) {
  .bookings-page {
    padding: 0px 30px;
  }
}