html,
body,
#root {
  height: 100%;
  font-family: 'DM Sans', sans-serif;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', sans-serif;
}

.formInput div:nth-child(3) {
  color: red;
  font-size: 15px;
}

.formInput {
  margin-bottom: 20px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bdbdbd;
}

/* The container class */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.delete-account {
  margin-top: 50px;
}

/* Define container widths for different viewport sizes */

/* Extra small devices (phones) */
@media (max-width: 575.98px) {
  .container {
    max-width: 100%;
  }

  .delete-account {
    margin-top: 8px;
  }
}

/* Small devices (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .container {
    max-width: 540px;
  }
}

/* Medium devices (desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    max-width: 720px;
  }
}

/* Large devices (large desktops) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container {
    max-width: 960px;
  }
}

/* Extra large devices (extra large desktops) */
@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

/* Extra large devices (extra large desktops) */
@media (min-width: 1600px) {
  .container {
    max-width: 1480px;
  }
}
