.blog-content {
  margin-top: 30px;
  font-size:18px;
}

.blog-content h2 {
  margin-top: 40px;
}

.blog-content h3 {
  margin-top: 40px;
}

.blog-content a {
  color: #11a5ea;
}

.blog-content {
  margin-top: 30px;
}

.blog-timeshare {
  margin-bottom: 30px;
  color: gray;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.share-content svg {
  font-size: 20px;
}
