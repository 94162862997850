.confirmation-email {
  max-width: 550px;
  background-color: white;
  margin: -80px 20px 0 20px;
  padding: 150px 0;
  min-height: 350px;
}

.confirmation-email-title {
  font-size: 30px;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .confirmation-email {
     padding-left: 20px;
     padding-right:20px;
   }
 }