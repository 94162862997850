.search-section {
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  padding: 0 20px 0 30px;
  width: fit-content;
  align-items: center;
}

.search-section .input {
  width: 350px;
}

.search-section .input .title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin: 5px 0;
  color: #333333;
}

.search-section input {
  border: none;
  width: 100%;
  outline: none;
}

.search-section input:disabled {
  background-color: white;
}

.search-section input {
  font-size: 18px !important;
}

.search-section .vl {
  width: 1px;
  height: 35px;
  background-color: black;
  margin: 0 40px;
}

:where(.css-dev-only-do-not-override-qfvobc).ant-picker {
  padding: 0;
}

.search-section .ant-picker {
  padding: 0px;
}

.search-section .ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 960px) {
  .search-section {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    opacity: 0.9;
  }

  .vl {
    display: none;
  }

  .input {
    margin-bottom: 10px;
  }

  .search-section .input {
    width: 300px;
  }

  .search-section .input .title {
    margin-top:15px;
    margin-bottom:5px;
    font-weight:bold;
    font-size:16px;
  }

  .title {
    padding-bottom:5px;
  }

  .location-search-input {
    margin-left:10px;
  }

  .ant-picker-input {
    margin-left:10px;
    margin-bottom:5px;
  }
}
