.quotes {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.quotes-top {
  display: flex;
  align-items: center;
}

.quotes-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  margin-left: 15px;
  color: #333333;
}

.quotes-icon {
  /*background-color: #11a5ea;*/
  padding: 20px;
  border-radius: 10px;
}

.quotes-bottom .quotes-bottom-p {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 30px;
  color: rgba(51, 51, 51, 0.501557);
}

@media screen and (max-width: 960px) {
  .quotes {
    width: 100%;
    padding-bottom: 10px;
  }

  .quotes-title {
    margin-top:30px;
    margin-left:0px;
  }

  .quotes-icon {
    display:inline-block;
  }

  .quotes-top {
    display:block;
  }
}
