.steps {
  display: flex;
  align-items: center;
}

.steps .circle {
  width: 30px;
  height: 30px;
  background-color: #2194ff;
  opacity: 0.5;
  border-radius: 50%;
}

.steps .hr {
  width: 100px;
  height: 2px;
  background-color: #2194ff;
  opacity: 0.5;
  margin: 0 10px;
}

.steps .hr.done {
  background-color: #29976d;
}

.steps .circle.progress {
  opacity: 1;
  border: 1px solid #2194ff;
  padding: 3px;
  box-sizing: border-box;
  background-clip: content-box;
}

.steps .circle.done {
  opacity: 1;
  background-color: #29976d;
  position: relative;
}

.steps .circle.done:after {
  content: '';
  background-color: transparent;
  position: absolute;
  left: 11px;
  top: 6px;
  width: 5px;
  border-bottom: 3px solid white;
  height: 11px;
  border-right: 3px solid white;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
