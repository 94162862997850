.sitemap-container {
  padding: 50px 150px;
}

.sitemap-container b {
  font-size: 25px;
  color: rgb(104, 104, 104);
}

.sitemap-container span {
  font-size: 25px;
  color: rgb(104, 104, 104);
}

.sitemap-container p {
  font-size: 18px;
  line-height: 30px;
  color: rgb(90, 90, 90);
}

.sitemap-container h1 {
  font-size:40px;
}

.Links {
  display: inline-block!important;
}

.Map {
  display: inline-block!important;
  vertical-align:top;
  margin-top:20px;
  margin-left:200px;
}

@media screen and (max-width: 960px) {
  .sitemap-container {
    padding: 20px 30px;
    text-align: center;
  }

  .Links {
    display:block!important;
  }

  .Map img {
    display:none;
  }
}
