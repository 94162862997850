.autocomplete-dropdown-container {
  position: absolute;
  max-height: 250px;
  overflow-y: auto;
  background: white;
  padding: 10px;
  z-index: 1000 !important;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 4px;
  transform-origin: bottom;
}

.autocomplete-dropdown-container-item-near {
  cursor: pointer;
}

.autocomplete-dropdown-container-item-near:hover {
  background-color: rgb(247, 247, 247);
}
