.search-mobile {
  position: absolute;
  bottom: 0;
  background: #00000085;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.search-mobile .title {
  color: white;
  font-weight: bold;
}

.search-mobile input {
  background-color: white !important;
  width: 140px !important;
  padding: 12px !important;
  background-color: white !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  border-radius: 4px !important;
  height: 22px;
}

.search-mobile .inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-mobile .ant-picker {
  padding: 0;
}
