.hotels-search {
  background-color: rgb(235 247 255);
  padding: 140px 0 100px;
  display: flex;
  justify-content: center;
}

.hotes-page-content {
  display: flex;
  align-items: center;
  height: 700px;
}

.hotes-page-content-mobile {
  height: 700px;
  overflow-y: auto;
}

.hotels-hotel {
  padding-left: 20px;
  padding-right: 20px;
}

.hotes-page-content-mobile .hotels-hotel::-webkit-scrollbar {
  display: none;
}

.hotels-grid {
  margin-bottom: 20px;
}

.hotes-page-content-mobile .ant-tabs,
.hotes-page-content-mobile .ant-tabs-content-holder,
.hotes-page-content-mobile .ant-tabs-content,
.hotes-page-content-mobile .ant-tabs-tabpane {
  height: 100%;
}

.sortby-container {
  position: sticky;
  top: 0px;
  z-index: 2;
  background: white;
  padding: 10px 0;
}

.scroll-info {
  position: absolute;
  background: #00000085;
  width: 100%;
  top: 84px;
  z-index: 1;
  color: white;
  padding: 5px 0;
  text-align: center;
}

.go-down-map {
  position: absolute;
  top: calc(100% - 190px);
  right: 10px;
}

.go-down-map .ant-spin-dot-item {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .hotels-hotel {
    width: 87% !important;
  }

  .hotes-page-content-mobile .ant-tabs-nav {
    width: 100% !important;
  }

  .hotes-page-content-mobile .ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
  }

  .hotes-page-content-mobile .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
}
