.hotels-search {
  background-color: rgb(235 247 255);
  padding: 140px 0 100px;
  display: flex;
  justify-content: center;
}

.faq-page {
  height: initial;
}

.faq-page-content {
  padding: 50px 150px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.6em;
}

.faq-page-content span {
  padding-bottom:50px;
}

@media screen and (max-width: 960px) {
  .faq-page-content {
    padding: 20px 30px;
  }
}