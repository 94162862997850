.press-container {
  padding: 50px 150px;
}

.articleTitle {
  font-size: 30px;
  color: rgb(104, 104, 104);
  font-weight: bold;
}

.subTitle {
  font-size: 23px;
  color: rgb(104, 104, 104);
  font-weight: bold;
}

.articleTitle a {
  text-decoration: none !important;
}

.articleTitle a:visited {
  color: rgb(104, 104, 104);
}

.press-container p {
  font-size: 18px;
  line-height: 30px;
  color: rgb(90, 90, 90);
}

.press-container h1 {
  font-size: 40px;
}

.storyPic {
  display: inline-block !important;
}

.storyInfo {
  display: inline-block !important;
  vertical-align: top;
  margin-top: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 960px) {
  .press-container {
    padding: 20px 30px;
    text-align: center;
  }
  .storyPic img {
    width: 100%;
    height: 100%;
  }

  .storyInfo {
    display: inline-block;
    margin-top: 30px;
    margin-left: 0px;
  }
}

.blog-single {
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #919191;
  margin-bottom: 35px;
}
