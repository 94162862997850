.hotels-search {
  background-color: rgb(235 247 255);
  padding: 140px 0 100px;
  display: flex;
  justify-content: center;
}

.errorPage {
  height: initial;
}

.errorPage-content {
  padding-bottom: 100px;
  padding-top:100px;
  font-size: 32px;
  line-height: 1.0em;
  text-align: center;
}

.errorPage-content span {
  padding-bottom:50px;
}

.error404 {
  font-size:180px;
  color:#11a5ea;
  font-weight: bold;
  padding-left:30px;
  padding-right:30px;
}

@media screen and (max-width: 960px) {
  .errorPage-content {
    padding: 20px 40px;
    font-size:24px;
    line-height: 1.6em;
  }

  .error404 {
    font-size:120px;
    padding-top:60px;
    padding-left:0px;
    padding-right:0px;
  }
  
}