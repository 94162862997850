.hotels-search {
  background-color: rgb(235 247 255);
  padding: 140px 0 100px;
  display: flex;
  justify-content: center;
}

.contest-page {
  height: initial;
}

.contest-page-rules {
  padding: 50px 150px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.6em;
}

.contest-page-rules span {
  padding-bottom:50px;
}

.contest-page-prizes {
  padding: 0px 150px 100px;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.6em;
}

.contest-page-prizes span {
  padding-bottom:50px;
}

h3 {
  font-weight: normal;
}

.logoDiv {
  float: right;
  padding-right: 150px;
  padding-top: 100px;
}

@media screen and (max-width: 960px) {
  .contest-page-rules, .contest-page-prizes {
    padding: 20px 30px;
  }

  .contest-page-rules {
    text-align:center;
  }

  .contest-page-prizes {
    padding-bottom:50px;
  }

  .contestLogo {
    height:200px;
  }

  .logoDiv {
    float:none;
    padding-top: 40px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}