.hotels-search {
  background-color: rgb(235 247 255);
  padding: 140px 0 100px;
  display: flex;
  justify-content: center;
}

.terms-page {
  height: initial;
}

.terms-page-content {
  padding: 50px 150px;
  display: flex;
  flex-direction: column;
}

.terms-page-content p,
.terms-page-content li {
  font-size: 18px;
  letter-spacing: 0.4px;
  line-height: 1.6em;
}

@media screen and (max-width: 960px) {
  .terms-page-content {
    padding: 20px 30px;
  }
}

/*
.terms-page-content a {
  color: #ff8b38;
}
*/